import React from 'react';
import EqGrid from './Grid/EqGrid';
import GridContainer from './Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import GridColumn from '../layout/Grid/GridColumn';
import styled from 'styled-components';
import logo from '../assets/mond-logo-2.svg';
import Button from '../components/Button.js';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { IconArrowRight, IconInsta, IconLinkedIn } from '../components/Icons';
import storeLogo from '../assets/logo-bicicli-store.svg';
import solutionsLogo from '../assets/logo-bicicli-solutions.svg';
import { above, below } from '../styles/MediaQueries';

const StyledFooter = styled.footer`
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: var(--light-gray);

  a:not(.inline-link) {
    border-bottom: none;
    font-weight: 400;
  }

  .inline-link {
    font-weight: 400;
  }

  img {
    width: 185px;
  }

  .social-media-icon-link {
    background-color: var(--text);
    padding: 0.75em;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    margin-bottom: 2rem;
    ${above.medium`
            margin-bottom: 0;
        `}
    svg {
      fill: var(--light-gray);
      margin: 0;
    }
    &:hover {
      opacity: 1;
      svg {
        fill: var(--moonlight);
      }
    }
  }
`;

const StyledFooterNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem 0;
  ${above.medium`
        flex-direction: column;
        margin: 0;
    `}
  a {
    display: inline-block;
    font-family: var(--sans-serif);
    font-size: var(--font-button);
    text-decoration: none;
    padding: 0 0.5em;
  }
`;

const StyledLegalLinks = styled.div`
  font-family: var(--sans-serif);
  text-align: center;
  a {
    display: inline-block;
    margin: 0.25em;
  }
`;

const StyledLogoLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  ${above.medium`
        align-items: flex-end;
        margin-top: 0;
        `}
  > * {
    width: 33vw;
    ${above.medium`
            width: 75%;
        `}
  }
`;

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function Footer() {
  // Static Query
  const data = useStaticQuery(graphql`
    query NonPageQuery {
      companyInfo: sanityCompanyInfo(
        _id: { regex: "/(drafts.|)companyInfo/" }
      ) {
        companyName
        instaLink
        linkedInLink
        newsletterLink
        companyAddress {
          email
          phone
          street
          houseNumber
          plz
          city
        }
      }
    }
  `);

  return (
    <StyledFooter>
      <GridContainer>
        <EqGrid sm="1" md="1" smallCentered="true">
          <img src={logo} alt="" />
        </EqGrid>

        <GridRow className="mt-2" smallCentered="true">
          <GridColumn start="1" span="4" className="hide-medium-up">
            <div>
              <a
                href={data.companyInfo.linkedInLink}
                className="social-media-icon-link"
                rel="noreferrer"
              >
                <IconLinkedIn className="social-icon" />
              </a>
              <a
                href={data.companyInfo.instaLink}
                className="social-media-icon-link"
                rel="noreferrer"
              >
                <IconInsta className="social-icon" />
              </a>
            </div>
          </GridColumn>

          <GridColumn start="1" span="4">
            <strong>mond.org</strong>
            <br />
            {data.companyInfo.companyName}
            <br />
            {data.companyInfo.companyAddress.street}{' '}
            {data.companyInfo.companyAddress.houseNumber}
            <br />
            {data.companyInfo.companyAddress.plz}{' '}
            {data.companyInfo.companyAddress.city}
            <br />
            <a href="mailto:beratung@mond.org" className="inline-link">
              {data.companyInfo.companyAddress.email}
            </a>
          </GridColumn>

          <GridColumn start="5" span="3">
            <StyledFooterNav>
              <Link to="/angebot/">Angebot</Link>
              <Link to="/projekte/">Projekte</Link>
              <Link to="/wir/">Wir</Link>
              <Link to="/magazin/">Magazin</Link>
            </StyledFooterNav>
          </GridColumn>

          <GridColumn start="8" span="3">
            <StyledButtonGroup>
              <Button to="/kontakt/" className="gray">
                Kontakt
              </Button>
              <a
                href={data.companyInfo.newsletterLink}
                className="button white mt-1"
                target="_blank"
                rel="noreferrer"
              >
                <IconArrowRight />
                Newsletter
              </a>
            </StyledButtonGroup>
          </GridColumn>
          <GridColumn start="11" span="2">
            <StyledLogoLinks>
              <a href="https://bicicli.de/" target="_blank" rel="noreferrer">
                <img src={storeLogo} alt="BICICLI Logo" />
              </a>
              <br />
              <a
                href="https://bicicli-solutions.de/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={solutionsLogo} alt="BICICLI Solutions Logo" />
              </a>
            </StyledLogoLinks>
          </GridColumn>
        </GridRow>

        <GridRow className="mt-4" verticalCenter={true} smallCentered="true">
          <GridColumn className="hide-medium-down" start="1" span="4">
            <div>
              <a
                href={data.companyInfo.linkedInLink}
                className="social-media-icon-link"
                target="_blank"
                rel="noreferrer"
              >
                <IconLinkedIn className="social-icon" />
              </a>
              <a
                href={data.companyInfo.instaLink}
                className="social-media-icon-link"
                target="_blank"
                rel="noreferrer"
              >
                <IconInsta className="social-icon" />
              </a>
            </div>
          </GridColumn>

          <GridColumn start="5" span="4" className="text-center">
            <StyledLegalLinks>
              <Link to="/datenschutz/">Datenschutz</Link> |
              <Link to="/impressum/">Impressum</Link> |
              <Link to="/gender/">Gender</Link> |
              <Link to="/presse/">Presse</Link>
            </StyledLegalLinks>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </StyledFooter>
  );
}
