import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { above } from '../../styles/MediaQueries';
import logo from '../../assets/mond-logo-2.svg';

// Settings
const logoBarSpacing = '3rem';

const StyledLogobar = styled.div`
  display: none;
  ${above.small`
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${logoBarSpacing};
    > * {
        max-width: 220px;
    }
`}

  a {
    border-bottom: none;
    &:hover {
      opacity: 1;
    }
  }
`;

export function DesktopLogoBar() {
  return (
    <StyledLogobar>
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
    </StyledLogobar>
  );
}
