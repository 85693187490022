import React from 'react';
import { above, below, size } from '../../styles/MediaQueries';
import styled from 'styled-components';

const StyledEqGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  max-width: ${size.large}px;
  overflow: hidden;
  justify-items: ${props => (props.centered ? 'center' : 'start')};
  text-align: ${props => (props.centered ? 'center' : 'left')};
  grid-template-columns: repeat(${props => props.sm}, minmax(0, 1fr));

  ${below.tiny`
		grid-template-columns: repeat(${props => props.xs}, minmax(0, 1fr));
	`}

  ${above.small`
    grid-template-columns: repeat(${props => props.md}, minmax(0, 1fr));
  `}

  ${below.medium`
    justify-items: ${props => (props.smallCentered ? 'center' : 'flex-start')};
    text-align: ${props => (props.smallCentered ? 'center' : 'left')};
    grid-row-gap: 0;
  `}

	> div {
    width: 100%;
  }
`;

export default function EqGrid({
  className,
  children,
  xs = 1,
  sm = 1,
  md = 3,
  centered,
  smallCentered,
}) {
  return (
    <StyledEqGrid
      className={className}
      xs={xs}
      sm={sm}
      md={md}
      smallCentered={smallCentered}
      centered={centered}
    >
      {children}
    </StyledEqGrid>
  );
}
