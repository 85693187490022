import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { above } from '../../styles/MediaQueries';
import { IconExternalLink2 } from '../../components/Icons.js';

const StyledSubNav = styled.nav`
  display: none;
  ${above.small`
  display: block;

  ul {
    display: flex;
		background-color: var(--light-gray);
		justify-content: center;
		${above.large`
			justify-content: flex-end;
		`}
		align-items: center;
		list-style-type: none;
		margin: 0;
  }

    a {
        padding: .5rem 1.5rem;
        display: inline-flex;
        align-items: center;
        font-size: var(--font-button);
        font-family: var(--sans-serif);
				text-decoration: none;
				border-bottom: none;
				font-weight: 400;
				// text-decoration: underline;
        svg {
          margin-right: 0;
          position: relative;
          bottom: .075em; // small fix
				}
				&:hover {
					opacity: 1;
				}
    }

		li {
			margin: 0;
		}

    li:first-child a {
				color: var(--brand);
				// font-weight: 600;
    }
    li:nth-child(2) a {
        &:hover {
            color: var(--solutions-brown);
        }
    }
    li:last-child a {
        padding-right: 3rem;
        &:hover {
            color: var(--store-green);
        }
    }
  `}
`;

export function DesktopSubNav() {
  return (
    <StyledSubNav>
      <ul>
        <li>
          <Link to="/">MOND Mobilitätsberatung</Link>
        </li>
        <li>
          <a
            href="https://bicicli-solutions.de/"
            target="_blank"
            rel="noreferrer"
          >
            <IconExternalLink2 size="1.5" />
            BICICLI Cycling Solutions
          </a>
        </li>
        <li>
          <a href="https://bicicli.de/" target="_blank" rel="noreferrer">
            <IconExternalLink2 size="1.5" />
            BICICLI Concept Store
          </a>
        </li>
      </ul>
    </StyledSubNav>
  );
}
