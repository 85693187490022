import React from "react";
import { above } from "../../styles/MediaQueries";
import styled from "styled-components";


const StyledGridColumn = styled.div`

${above.small`
  grid-column: ${props => props.start} / span ${props => props.span};
`}

`;


export default function GridColumn({ className, children, start = "1", span = "12"}) {
  return (
    <StyledGridColumn className={className} start={start} span={span}>
        {children}
    </StyledGridColumn>
  )
}