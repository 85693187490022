// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-js": () => import("./../../../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-gender-js": () => import("./../../../src/pages/gender.js" /* webpackChunkName: "component---src-pages-gender-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-magazin-js": () => import("./../../../src/pages/magazin.js" /* webpackChunkName: "component---src-pages-magazin-js" */),
  "component---src-pages-presse-js": () => import("./../../../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-wir-js": () => import("./../../../src/pages/wir.js" /* webpackChunkName: "component---src-pages-wir-js" */),
  "component---src-templates-single-project-page-js": () => import("./../../../src/templates/SingleProjectPage.js" /* webpackChunkName: "component---src-templates-single-project-page-js" */)
}

