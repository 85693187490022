import { createGlobalStyle } from 'styled-components';
import { above, below } from '../styles/MediaQueries';

const GlobalStyle = createGlobalStyle`

*,
*::before,
*::after {
  box-sizing: border-box;
}

  body {
    /* background-color: red; */
  }

  .section {
    padding: 2rem 0;
  }

  img {
    max-width: 100%;
  }


  // Utility Classes
  .mt-0 {
    margin-top: 0;
  }

	.mt-1 {
    margin-top: 1rem;
  }

  .mt--2 {
    margin-top: -2rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .mt-6 {
    margin-top: 6rem;
  }

  .mt-4 {
    margin-top: 4rem;
  }

	.pt-0 {
		padding-top: 0 !important;
	}

  ${below.medium`
  .hide-medium-down {
    display: none;
  }
  `}

  ${above.medium`
  .hide-medium-up {
    display: none;
  }
  `}

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

	// for external a href links
	.button {
		// 1 layout
		font-weight: 400;
		display: inline-flex;
		align-items: center;
		/* justify-content: center; */
		text-decoration: none;
		min-width: 17ch;
		/* text-align: left; */
		line-height: 1.2;
		font-size: var(--font-button);
		padding: 1em 2em;
		// 2 lipstick
		color: white;
		background-color: var(--brand);
		border-radius: 6em;
		font-family: var(--sans-serif);
		transition: all .2s ease;

		&:hover,
		&:active{
			opacity: 1;
			background-color: var(--brand-70);
		}
		&.white {
    color: var(--text);
    background-color: white;

    &:hover,
    &:active {
      opacity: .8;
  		}
		}

	}

	::selection {
  	color: white;
  	background: var(--brand);
	}

`;

export default GlobalStyle;
