import React from 'react';
import { above, below } from '../../styles/MediaQueries';
import styled, { css } from 'styled-components';

const decorCss = css`
  position: relative;
  &:before {
    position: absolute;
    top: -2rem;
    left: 2rem;
    height: 500px;
    width: 500px;
    /* width: 200px; */
    background-color: var(--moonlight-20);
    border-radius: 50%;
    /* clip-path: circle(50px at center); */
    content: '';
    z-index: -1;
    pointer-events: none;
  }
`;

const StyledGridRow = styled.div`
  ${below.medium`
  text-align: ${props => (props.smallCentered ? 'center' : 'left')};
	`}

  ${above.medium`
		display: grid;
		grid-gap: 2rem;
		z-index: 1;
		grid-template-columns: repeat(12, 1fr);
		align-items: ${props => (props.verticalCenter ? 'center' : 'start')};
		text-align: ${props => (props.centered ? 'center' : 'left')};
  `}
	/* > * {
    grid-column: ${props => props.start} / span ${props => props.span};
  } */

	${props => (props.decor ? decorCss : '')}
`;

export default function GridRow({
  children,
  verticalCenter = 'true',
  className,
  decor,
  centered,
  style,
  smallCentered,
}) {
  return (
    <StyledGridRow
      style={style}
      verticalCenter={verticalCenter}
      className={className}
      centered={centered}
      smallCentered={smallCentered}
      decor={decor}
    >
      {children}
    </StyledGridRow>
  );
}
