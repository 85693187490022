import React from 'react';
import styled from 'styled-components';

// as used inside buttons
const StyledIcon = styled.svg`
  width: ${props => (props.size ? `${props.size}em` : `1em`)};
  height: ${props => (props.size ? `${props.size}em` : `1em`)};
  fill: currentColor;
  margin-right: 0.25em;
  fill: ${props => (props.color ? props.color : 'currentColor')};
  /* position: relative; */
  /* top: 0.25em; */
`;

export function IconArrowRight({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
    </StyledIcon>
  );
}

export function IconExternalLink({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      transform="rotate(45)"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
    >
      <g>
        <rect fill="none" />
        <path d="M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20 M12,22c5.52,0,10-4.48,10-10c0-5.52-4.48-10-10-10 C6.48,2,2,6.48,2,12C2,17.52,6.48,22,12,22L12,22z M11,12l0,4h2l0-4h3l-4-4l-4,4H11z" />
      </g>
    </StyledIcon>
  );
}

export function IconArrowLeft({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    </StyledIcon>
  );
}

export function IconClose({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
    </StyledIcon>
  );
}

export function IconExternalLink2({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      transform="rotate(-45)"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
    >
      <g>
        <path d="M8.54,0 C4.9,0 1.79,2.35 0.56,5.65 L1.88,5.65 C2.24,4.84 2.74,4.1 3.36,3.46 C4.74,2.04 6.58,1.25 8.54,1.25 C10.5,1.25 12.34,2.03 13.72,3.46 C15.1,4.88 15.86,6.78 15.86,8.79 C15.86,10.8 15.1,12.7 13.72,14.12 C12.34,15.54 10.5,16.33 8.54,16.33 C6.58,16.33 4.74,15.55 3.36,14.12 C2.73,13.48 2.24,12.73 1.88,11.93 L0.56,11.93 C1.79,15.24 4.9,17.58 8.54,17.58 C13.26,17.58 17.08,13.64 17.08,8.79 C17.08,3.94 13.26,0 8.54,0" />
        <polygon points="6.67 6.13 8.65 8.17 0 8.17 0 9.42 8.65 9.42 6.67 11.46 7.53 12.35 10.98 8.79 7.53 5.24" />
      </g>
    </StyledIcon>
  );
}

export function IconInsta({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
    </StyledIcon>
  );
}

export function IconLinkedIn({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
    </StyledIcon>
  );
}

export function IconAirport({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#3255A4"
          strokeWidth="2"
          d="M30.91,3.11 C31.5934443,2.78848696 32.3772983,2.75457307 33.0859576,3.01585595 C33.7946169,3.27713883 34.3688452,3.81177801 34.68,4.5 C35.3429151,5.92802528 34.7316468,7.62351414 33.31,8.3 L8.92,19.81 C8.11,20.19 7.69,20.04 7.48,19.81 L1.09,13.34 C0.76,13.01 1.09,12.56 1.09,12.56 L1.92,11.81 C2.33,11.43 3.35,11.96 3.35,11.96 L8.06,13.89 L13.41,11.37 C13.41,11.37 6.09,2.43 5.74,2 C5.58040117,1.87596021 5.49398472,1.67989734 5.51010384,1.47840831 C5.52622297,1.27691927 5.64271088,1.09708875 5.82,1 C5.82,1 7.04,0.33 7.49,0.11 C8.00849714,-0.053301837 8.56797575,-0.0285148104 9.07,0.18 L22.48,7.04 L30.91,3.11 Z"
          transform="translate(7 17)"
        />
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconArch({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(9 8)">
          <polyline points="6.01 12.39 .52 30.67 3.81 31.81 9.27 14.66" />
          <line x1="1.98" x2=".85" y1="31.18" y2="35.41" />
          <polyline points="13.2 12.39 18.69 30.67 15.4 31.81 10.27 14.66" />
          <line x1="17.23" x2="18.36" y1="31.18" y2="35.41" />
          <path d="M5.44,11.27 C5.55293823,13.3177874 7.30193896,14.8878622 9.35,14.78 C11.3998921,14.8879705 13.1517687,13.3193259 13.27,11.27 L13.27,6.6 L5.44,6.6 L5.44,11.27 Z" />
          <line
            x1="1.27"
            x2="1.27"
            y1="6.41"
            y2=".41"
            transform="translate(8)"
          />
          <polyline points="21.64 34.41 28.25 34.41 28.25 1.41 19.64 1.41 19.64 23.8" />
          <line x1="28.25" x2="25.38" y1="5.71" y2="5.71" />
          <line x1="28.25" x2="25.38" y1="11.45" y2="11.45" />
          <line x1="28.25" x2="25.38" y1="17.19" y2="17.19" />
          <line x1="28.25" x2="25.38" y1="22.93" y2="22.93" />
          <line x1="28.25" x2="25.38" y1="28.67" y2="28.67" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconBike({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(3 13)">
          <path
            stroke="#3255A4"
            strokeWidth="2"
            d="M13.54,17.43 C13.54,21.0198509 10.6298509,23.93 7.04,23.93 C3.45014913,23.93 0.54,21.0198509 0.54,17.43 C0.54,13.8401491 3.45014913,10.93 7.04,10.93 C8.76390718,10.93 10.4172076,11.6148195 11.6361941,12.8338059 C12.8551805,14.0527924 13.54,15.7060928 13.54,17.43 Z"
          />
          <polyline
            stroke="#3255A4"
            strokeLinejoin="round"
            strokeWidth="2"
            points="13.91 10.29 6.54 18 16.54 19.93 11.84 4.93"
          />
          <polyline
            stroke="#3255A4"
            strokeWidth="2"
            points="21.93 .93 25.54 1.01 25.52 19.93 16.54 19.93"
          />
          <line
            x1="10.54"
            x2="15.54"
            y1="5.43"
            y2="5.43"
            stroke="#3255A4"
            strokeWidth="2"
          />
          <path
            stroke="#3255A4"
            strokeWidth="2"
            d="M25.54,7.93 L25.54,19.93 L28.63,19.93 C28.5698483,19.6000705 28.539725,19.2653679 28.54,18.93 C28.54,15.6162915 31.2262915,12.93 34.54,12.93 C37.8537085,12.93 40.54,15.6162915 40.54,18.93 C40.535281,19.265127 40.505195,19.5994158 40.45,19.93 L43.54,19.93 L43.54,7.93 L25.54,7.93 Z"
          />
          <path
            stroke="#3255A4"
            strokeWidth="2"
            d="M40.54,18.93 C40.54,22.2437085 37.8537085,24.93 34.54,24.93 C31.2262915,24.93 28.54,22.2437085 28.54,18.93 C28.54,15.6162915 31.2262915,12.93 34.54,12.93 C36.1312989,12.93 37.6574224,13.562141 38.7826407,14.6873593 C39.907859,15.8125776 40.54,17.3387011 40.54,18.93 L40.54,18.93 Z"
          />
          <path
            fill="#3255A4"
            d="M36,18.5 C36,19.3284271 35.3284271,20 34.5,20 C33.6715729,20 33,19.3284271 33,18.5 C33,17.6715729 33.6715729,17 34.5,17 C35.3284271,17 36,17.6715729 36,18.5"
          />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconBoss({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(13 10)">
          <polygon points="0 26 24 26 24 9 0 9" />
          <path d="M7,7 L7,5.25 C7.17549508,2.34106522 9.58577625,0.0718132005 12.5,0.0718132005 C15.4142238,0.0718132005 17.8245049,2.34106522 18,5.25 L18,7" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconBuilding({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(5 11)">
          <polyline points="23.14 30.2 23.14 2.6 38.14 2.6 38.14 30.2" />
          <path d="M10.62,7.48 C11.9292429,7.32028317 12.91973,6.21879319 12.9400005,4.9 C12.9404261,4.19985866 12.656286,3.52960882 12.1527982,3.04309252 C11.6493104,2.55657622 10.9697153,2.29557633 10.27,2.32 C9.67318858,1.05466254 8.39902076,0.248079239 7,0.249970833 C5.06225861,0.242402755 3.44142199,1.71984097 3.27,3.65 C2.69928287,3.52973679 2.10474288,3.67262739 1.65100821,4.0391054 C1.19727353,4.40558341 0.932498745,4.95675473 0.93,5.54 C0.94564165,6.07230434 1.17292738,6.57639049 1.56148936,6.94055002 C1.95005133,7.30470955 2.46780027,7.49886846 3,7.48 L10.62,7.48 Z" />
          <line x1="33.14" x2="33.14" y1="7.48" y2="9.92" />
          <line x1="33.14" x2="33.14" y1="12.44" y2="14.88" />
          <line x1="33.14" x2="33.14" y1="17.4" y2="19.84" />
          <line x1="33.14" x2="33.14" y1="22.36" y2="24.8" />
          <line x1="33.14" x2="33.14" y1="27.33" y2="29.76" />
          <line x1="28.14" x2="28.14" y1="7.48" y2="9.92" />
          <line x1="28.14" x2="28.14" y1="12.44" y2="14.88" />
          <line x1="28.14" x2="28.14" y1="17.4" y2="19.84" />
          <line x1="28.14" x2="28.14" y1="22.36" y2="24.8" />
          <line x1="28.14" x2="28.14" y1="27.33" y2="29.76" />
          <polyline points="1.64 30.2 1.64 15.6 16.64 15.6 16.64 30.2" />
          <line x1="11.64" x2="11.64" y1="19.48" y2="22.87" />
          <line x1="11.64" x2="11.64" y1="26.38" y2="29.77" />
          <line x1="6.64" x2="6.64" y1="19.48" y2="22.87" />
          <line x1="6.64" x2="6.64" y1="26.38" y2="29.77" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconCity({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(1 12)">
          <polyline points="12.85 20.98 12.85 26.23 16.6 26.23 16.6 21.85 19.73 21.85 19.73 26.23 23.48 26.23 23.48 21.29" />
          <polyline points="10.97 19.66 18.16 12.47 25.35 19.66" />
          <g transform="translate(30 7)">
            <polyline points="3.34 19.81 .09 19.81 .09 .85 16.97 .85 16.97 19.81 13.58 19.81" />
            <polygon points="6.69 19.81 10.37 19.81 10.37 14.98 6.69 14.98" />
          </g>
          <polygon points="39.53 16.79 42.97 16.79 42.97 11.39 39.53 11.39" />
          <path d="M4.3 2.57C2.24826215 2.57.585 4.23326215.585 6.285.585 8.33673785 2.24826215 10 4.3 10L4.3 2.57zM11.69.73C11.7766282 1.40444655 11.5450531 2.08087019 11.0632512 2.56071348 10.5814494 3.04055677 9.90408809 3.26937469 9.23 3.18 9.90144461 3.11006563 10.5686199 3.34667733 11.0459713 3.8240287 11.5233227 4.30138008 11.7599344 4.96855539 11.69 5.64 11.5912995 4.96207737 11.818521 4.27735626 12.3029386 3.79293863 12.7873563 3.308521 13.4720774 3.08129952 14.15 3.18 13.4759119 3.26937469 12.7985506 3.04055677 12.3167488 2.56071348 11.8349469 2.08087019 11.6033718 1.40444655 11.69.73L11.69.73z" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconCowork({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#3255A4"
          strokeWidth="2"
          d="M3 15L3 1C3 .44771525 3.44771525 0 4 0L28 0C28.5522847 0 29 .44771525 29 1L29 15M19 17L19 18 13 18 13 17 0 17 0 20C0 20.5522847.44771525 21 1 21L31 21C31.5522847 21 32 20.5522847 32 20L32 17 19 17z"
          transform="translate(8 17)"
        />
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconCulture({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" transform="translate(6 11)">
          <g transform="translate(17 9)">
            <polyline
              strokeWidth="2"
              points="10.9 9.3 3.6 18.3 5.6 20.3 14.8 13"
            />
            <line x1="3.1" x2=".1" y1="20.7" y2="23.7" strokeWidth="2" />
            <line x1="10.6" x2="9.6" y1="13.3" y2="14.3" strokeWidth="4" />
            <line x1="11.2" x2="18.6" y1="4.9" y2="12.3" strokeWidth="2" />
            <path
              strokeWidth="2"
              d="M14.4,13.3 L17.6,13.3 C20.5,12.8 22.7,10.3 22.7,7.3 C22.9,4 20.4,1.1 17.1,0.9 C13.8,0.7 10.9,3.2 10.7,6.5 L10.7,9.6 L14.4,13.3 Z"
            />
          </g>
          <g strokeWidth="2">
            <path d="M20.6,11.7 L20.6,0.7 C18.2,3.1 3,3.1 0.6,0.7 L0.6,11.7 C0.6,17.7 8.7,22.7 10.6,22.7 C12.5,22.7 20.6,17.8 20.6,11.7 Z" />
            <path d="M13 8.9C13.1 7.8 14 7.1 15 7.1 15.9 7.2 16.7 7.9 16.8 8.9M4.4 8.9C4.5 7.8 5.4 7.1 6.4 7.1 7.3 7.2 8.1 7.9 8.2 8.9M6.8 13.6C6.8 15.7 8.5 17.4 10.6 17.4 12.7 17.4 14.4 15.7 14.4 13.6" />
          </g>
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconFranchise({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(10 11)">
          <path d="M21.49 12.83C21.5142536 14.8872973 20.2904037 16.7544457 18.3942506 17.5529842 16.4980974 18.3515227 14.3072212 17.9224387 12.8523913 16.4676087 11.3975613 15.0127788 10.9684773 12.8219026 11.7670158 10.9257494 12.5655543 9.02959629 14.4327027 7.80574643 16.49 7.83 19.2378934 7.86239503 21.457605 10.0821066 21.49 12.83L21.49 12.83zM3.80002649 6.52C3.80002649 7.28938598 3.33597974 7.98281919 2.62476994 8.27630752 1.91356013 8.56979585 1.09555764 8.40539864.552956942 7.85992704.0103562432 7.31445544-.149722068 6.49559681.147515238 5.78594565.444752543 5.07629449 1.14062468 4.6159506 1.91 4.6199735 2.4129966 4.6199735 2.89524719 4.82048952 3.24997981 5.17709903 3.60471243 5.53370854 3.80266136 6.01701044 3.80002649 6.52L3.80002649 6.52zM29.0700265 2.09C29.0740494 2.85937532 28.6137055 3.55524746 27.9040544 3.85248476 27.1944032 4.14972207 26.3755446 3.98964376 25.830073 3.44704306 25.2846014 2.90444236 25.1202042 2.08643987 25.4136925 1.37523006 25.7071808.664020256 26.400614.2 27.17.2 28.2154482.2 29.0644976 1.04456633 29.0700265 2.09L29.0700265 2.09zM3.8 27.36C3.8 28.129386 3.33597974 28.8228192 2.62476994 29.1163075 1.91356013 29.4097958 1.09555764 29.2453986.552956942 28.699927.0103562432 28.1544554-.149722068 27.3355968.147515238 26.6259456.444752543 25.9162945 1.14062468 25.4559506 1.91 25.4599735 2.95543367 25.4655024 3.8 26.3145518 3.8 27.36zM18.33 27.36C18.33 28.129386 17.8659797 28.8228192 17.1547699 29.1163075 16.4435601 29.4097958 15.6255576 29.2453986 15.0829569 28.699927 14.5403562 28.1544554 14.3802779 27.3355968 14.6775152 26.6259456 14.9747525 25.9162945 15.6706247 25.4559506 16.44 25.4599735 17.4854337 25.4655024 18.33 26.3145518 18.33 27.36L18.33 27.36z" />
          <line x1="3.25" x2="12.87" y1="26.02" y2="16.42" />
          <line x1="20.02" x2="25.83" y1="9.27" y2="3.43" />
          <line x1="3.61" x2="11.79" y1="7.34" y2="10.84" />
          <line x1="25.36" x2="20.79" y1="17.29" y2="15.4" />
          <line x1="16.44" x2="16.44" y1="25.47" y2="17.89" />
          <path d="M29.07,17.89 C29.07,18.6584783 28.6070799,19.3512872 27.8970985,19.6453711 C27.1871172,19.939455 26.3698933,19.7768991 25.8264971,19.2335029 C25.2831009,18.6901067 25.120545,17.8728828 25.4146289,17.1629015 C25.7087128,16.4529201 26.4015217,15.99 27.17,15.99 C28.219341,15.99 29.07,16.840659 29.07,17.89 L29.07,17.89 Z" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconGastro({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#3255A4"
          strokeWidth="2"
          d="M7.37.23L7.37 6.29C7.35904398 7.23763299 6.58769632 8 5.64 8L2.17 8C1.21293208 7.99449952.44 7.21708373.44 6.26L.44.23M18.56 5.28C18.6484466 6.23711279 18.3164762 7.18540361 17.6503816 7.87837235 16.984287 8.57134109 16.0498611 8.94054038 15.09 8.89 14.1301389 8.94054038 13.195713 8.57134109 12.5296184 7.87837235 11.8635238 7.18540361 11.5315534 6.23711279 11.62 5.28 11.62 2.89 13.17.23 15.09.23 17.01.23 18.56 2.89 18.56 5.28z"
          transform="translate(15 14)"
        />
        <line
          x1="30.09"
          x2="30.09"
          y1="22"
          y2="40"
          stroke="#3255A4"
          strokeWidth="2"
        />
        <line
          x1="19"
          x2="19"
          y1="14.23"
          y2="40"
          stroke="#3255A4"
          strokeWidth="2"
        />
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconHealth({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(13 14)">
          <line x1="15.87" x2=".27" y1="8.45" y2="23.98" />
          <path d="M1.72,18 C-4.36,3.17 24.31,0 24.31,0 C24.31,0 21.17,28.57 6.4,22.64" />
          <polyline points="12.5 17.45 6.88 17.45 6.88 11.83" />
          <polyline points="15.31 12.95 11.37 12.95 11.37 9.02" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconHotel({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(17 11)">
          <path d="M15.29,15.27 L15.29,7.77 C15.29,3.62786438 11.9321356,0.27 7.79,0.27 C3.64786438,0.27 0.29,3.62786438 0.29,7.77 C0.29,8.8745695 1.1854305,9.77 2.29,9.77 C3.3945695,9.77 4.29,8.8745695 4.29,7.77 C4.29,7.77 4.29,7.77 4.29,7.77 C4.29,7.77 4.29,7.77 4.29,7.77 C4.29806446,6.21248683 5.33437705,4.84770387 6.83249971,4.42162781 C8.33062236,3.99555174 9.93004461,4.61071414 10.7565383,5.93087341 C11.583032,7.25103269 11.4377084,8.95850374 10.4,10.12 C7.65,12.87 0.29,12.41 0.29,17.31 L0.29,29.31 C0.29,29.8622847 0.73771525,30.31 1.29,30.31 L14.29,30.31 C14.8422847,30.31 15.29,29.8622847 15.29,29.31 L15.29,15.31 L15.29,15.27 Z" />
          <polygon points="4.29 26.27 11.29 26.27 11.29 17.27 4.29 17.27" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconImmo({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(11 11)">
          <g transform="translate(0 6)">
            <polygon points=".85 22.06 20.35 22.06 20.35 .15 .85 .15" />
            <polygon points="8.47 22.06 12.73 22.06 12.73 16.48 8.47 16.48" />
          </g>
          <polygon points="11.76 16.48 15.73 16.48 15.73 10.23 11.76 10.23" />
          <polyline points="7.73 3.79 7.73 .48 26.73 .48 26.73 26.06 22.73 26.06" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}

export function IconShop({ color, className, size }) {
  return (
    <StyledIcon
      size={size}
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#3255A4" strokeWidth="2" transform="translate(11 12)">
          <g transform="translate(3 11)">
            <polygon points="14 15.9 19.9 15.9 19.9 4.9 14 4.9" />
            <polyline points="23.9 1.6 23.9 15.9 .2 15.9 .2 .9" />
          </g>
          <polygon points="7.1 21.9 14 21.9 14 15.9 7.1 15.9" />
          <path d="M25.2,0.9 L4.9,0.9 L0.3,9.2 C0.3,11.2 2,12.9 4,12.9 C6,12.9 7.7,11.2 7.7,9.2 C7.7,9.2 7.7,9.2 7.7,9.2 C7.6,11.2 9.2,13 11.2,13.1 C13.2,13.2 15,11.6 15.1,9.6 C15.1,9.5 15.1,9.3 15.1,9.2 C15,11.2 16.6,13 18.6,13.1 C20.6,13.2 22.4,11.6 22.5,9.6 C22.5,9.5 22.5,9.3 22.5,9.2 C22.4,11.2 24,13 26,13.1 C28,13.2 29.8,11.6 29.9,9.6 C29.9,9.5 29.9,9.3 29.9,9.2 L25.2,0.9 Z" />
          <line x1=".2" x2="29.8" y1="8.9" y2="8.9" />
          <line x1="15" x2="15" y1=".9" y2="9.2" />
          <line x1="7.6" x2="10.1" y1="9.2" y2=".9" />
          <line x1="22.4" x2="20" y1="9.2" y2=".9" />
        </g>
        <rect width="50" height="50" />
      </g>
    </StyledIcon>
  );
}
