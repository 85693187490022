import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Section from '../layout/Section';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import GridColumn from '../layout/Grid/GridColumn';
import EqGrid from '../layout/Grid/EqGrid';

const StyledAwardWrapper = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: none;

  > div {
    margin-top: auto;
    height: 100%;
  }

  p {
    margin-top: auto;
    text-align: center;
  }

  &:hover {
    opacity: 1;
    img {
      filter: grayscale(0%);
    }
  }

  img {
    filter: grayscale(100%);
  }
`;

// Single Award
function SingleAward({ award }) {
  return (
    <StyledAwardWrapper
      href={award.link}
      target="_blank"
      rel="noreferrer"
      className="link--wrapper"
    >
      <Img
        fluid={award.image.asset.fluid}
        durationFadeIn={400}
        alt=""
        style={
          {
            // height: "100%",
          }
        }
        imgStyle={{
          objectFit: 'contain',
          marginTop: 'auto',
        }}
      />
      {/* {award.image.caption && <p>{award.image.caption}</p>} */}
    </StyledAwardWrapper>
  );
}

// Loopin' over it
export default function Awards() {
  // Static Query
  const data = useStaticQuery(graphql`
    query AwardQuery {
      awards: allSanityAwards {
        nodes {
          name
          link
          image {
            caption
            asset {
              fluid(maxWidth: 650) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>Auszeichnungen</h2>
              <p>
                Klicken Sie auf eines der Logos, um den Beitrag auf bicicli.de
                zu lesen.
              </p>
              <EqGrid xs="3" sm="3">
                {data.awards.nodes.map(award => (
                  <SingleAward key={award.name} award={award} />
                ))}
              </EqGrid>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}
