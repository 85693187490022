import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  z-index: 2;
  padding: 4rem 0;

  ${props => {
    if (props.color === 'subtle') {
      return `
        background-color: var(--brand-10);
      `;
    } else if (props.color === 'subtle05') {
      return `
        background-color: var(--brand-05);
      `;
    } else {
      return `
        background-color: white;
    `;
    }
  }}
`;

export default function Section({ color, children, id, className }) {
  return (
    <StyledSection color={color} id={id} className={className}>
      {children}
    </StyledSection>
  );
}
