import React from 'react';
import styled from 'styled-components';

const StyledGridContainer = styled.div`
  max-width: 1200px;
  padding: 1.25rem;
  margin: 0 auto;
`;

export default function GridContainer({ children }) {
  return <StyledGridContainer>{children}</StyledGridContainer>;
}
