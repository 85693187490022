import { createGlobalStyle } from 'styled-components';
import sansSerifBookWoff from '../assets/fonts/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff';
import sansSerifBookWoff2 from '../assets/fonts/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2';
import sansSerifDemiWoff from '../assets/fonts/e4eacf8d-aba1-40d4-9597-1198e91dcf00.woff';
import sansSerifDemiWoff2 from '../assets/fonts/848602e3-bda3-4b1c-976b-fe26eecfab31.woff2';
import sansSerifBoldWoff from '../assets/fonts/baecea54-cfd3-4578-8717-abf89eba62b6.woff';
import sansSerifBoldWoff2 from '../assets/fonts/1a34942e-33ed-43bb-b229-7460d55b49f7.woff2';
import serifBookWoff from '../assets/fonts/merriweather-v22-latin-regular.woff';
import serifBookWoff2 from '../assets/fonts/merriweather-v22-latin-regular.woff2';
import serifItalicWoff from '../assets/fonts/merriweather-v22-latin-italic.woff';
import serifItalicWoff2 from '../assets/fonts/merriweather-v22-latin-italic.woff2';
import serifBoldWoff from '../assets/fonts/merriweather-v22-latin-700.woff';
import serifBoldWoff2 from '../assets/fonts/merriweather-v22-latin-700.woff2';

const Typography = createGlobalStyle`

@font-face {
  /* font-family:"Futura PT W01 Book"; */
  /* font-family:"Futura PT W01 Book"; */
	font-family:"Futura PT";
  src: url(${sansSerifBookWoff2}) format("woff2"), url(${sansSerifBookWoff}) format("woff");
  font-weight: 400;
  font-style: normal;
	font-display: swap;
}

@font-face {
  font-family:"Futura PT";
  src: url(${sansSerifDemiWoff2}) format("woff2"), url(${sansSerifDemiWoff}) format("woff");
  font-weight: 600;
  font-style: normal;
	font-display: swap;
}

@font-face {
  font-family:"Futura PT";
  src: url(${sansSerifBoldWoff2}) format("woff2"), url(${sansSerifBoldWoff}) format("woff");
  font-weight: 700;
  font-style: normal;
	font-display: swap;
}

 @font-face {
  font-family: "Merriweather";
  src: url(${serifBookWoff2}) format("woff2"), url(${serifBookWoff}) format("woff");
  font-weight: 400;
  font-style: normal;
	font-display: swap;
}

 @font-face {
  font-family: "Merriweather";
  src: url(${serifItalicWoff2}) format("woff2"), url(${serifItalicWoff}) format("woff");
  font-weight: 400;
  font-style: italic;
	font-display: swap;
}

 @font-face {
  font-family: "Merriweather";
  src: url(${serifBoldWoff2}) format("woff2"), url(${serifBoldWoff}) format("woff");
  font-weight: 700;
  font-style: normal;
	font-display: swap;
}



:root {
  --base-font-size: 16;

  --font-2xl: calc(30 / var(--base-font-size) * 1rem);
  --font-xl: calc(22 / var(--base-font-size) * 1rem);
  --font-l: calc(20 / var(--base-font-size) * 1rem);
  --font-button: calc(17 / var(--base-font-size) * 1rem);
  --font-base: calc(17 / var(--base-font-size) * 1rem);
  --font-s: calc(14 / var(--base-font-size) * 1rem);
  --font-xs: calc(13 / var(--base-font-size) * 1rem);

  --serif: "Merriweather", serif;
  --sans-serif: "Futura PT", "Helvetica", serif;
}

@media (min-width: 768px) {
  :root {
    --base-font-size: 16;

    --font-2xl: calc(43 / var(--base-font-size) * 1rem);
    --font-xl: calc(30 / var(--base-font-size) * 1rem);
    --font-l: calc(24 / var(--base-font-size) * 1rem);
    --font-button: calc(17 / var(--base-font-size) * 1rem);
    --font-base: calc(16 / var(--base-font-size) * 1rem);
    --font-s: calc(14 / var(--base-font-size) * 1rem);
    --font-xs: calc(12 / var(--base-font-size) * 1rem);
  }
}

body {
  font-size: calc(var(--base-font-size) / 16 * 1rem);
  line-height: 1.7;
  color: var(--text);
  font-family: var(--serif);
	font-size: var(--font-base);
  // TODO: try out impact of these rules
  -webkit-font-smoothing: antialiased;
  -moz-osx-smoothing: grayscale;
}

p,
li,
h1,
h2,
h3,
h4 {
  /* word-break: break-all; */
	/* word-wrap: break-word; */
  /* hyphens: auto; */
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  font-family: var(--sans-serif);
}

h1 {
  font-weight: 600;
	margin-bottom: 4rem;
	font-size: var(--font-2xl);
}

h2 {
  /* text-transform: uppercase; */
  font-weight: bold;
  font-size: var(--font-xl);
	margin-bottom: 4rem;
}

h2+h3 {
	/* color: red; */
}

h3 {
  font-size: var(--font-base);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
	margin-top: 2.5rem;
}

.bold {
  font-family: "Futura PT";
  font-weight: 700;
}

b, strong {
  font-weight: 700;
}

.normal {
  font-family: "Futura PT";
  font-weight: 400;
}

.demi {
  font-family: "Futura PT";
  font-weight: 600;
}


a.link--wrapper {
  border-bottom: none;
  text-decoration: none;
	font-weight: 400;
	&:hover {
		opacity: 1;
	}

}

a, .inline-link {
  border-bottom: 1px solid;
  text-decoration: none;
  color: inherit;
  display: inline-block;
  font-weight: 700;
	cursor: pointer;
	&:hover {
		opacity: .66;
	}
  /* margin-top: 2rem; */
}

/* as used for teaser links */
.link--wrapper {
  text-decoration: none;
}

blockquote {
  margin: 2rem 0 2rem 4rem;
}

blockquote,
blockquote p {
  font-family: var(--sans-serif);
	line-height: 1.4;
}

.blockquote--decor {
    position: relative;
    &:before {
      content: '»';
      font-family: var(--sans-serif);
      font-weight: 700;
      position: absolute;
      z-index: -2;
      color: var(--moonlight);
      top: 0;
      left: -4rem;
      font-size: 6rem;
			z-index: 0;
			line-height: 0;
    }
  }


ul {
  padding-left: 3em;
  /* margin: 0; */
	margin-top: 1.5rem;
  list-style-type: circle;
	/* line-height: 1.2; */
}

li {
    /* font-family: var(--sans-serif);
    font-size: var(--font-button); */
    margin-bottom: .5rem;
		list-style-position: outside;
  }

ol {
	padding-left: 3em;
	margin-top: 1.5rem;
	/* line-height: 1.2; */
	li {
		font-family: var(--serif);
		font-size: var(--font-base);
	}
}

`;

export default Typography;
