import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
// import { navigate } from '@reach/router';
import { IconArrowRight } from '../components/Icons';

const StyledButton = styled(Link)`
  // 1 layout
  display: inline-flex;
  align-items: center;
  /* justify-content: center; */
  text-decoration: none;
  min-width: 17ch;
  /* text-align: left; */
  line-height: 1.2;
  font-size: var(--font-button);
  padding: 1em 2em;
  // 2 lipstick
  color: white;
  background-color: var(--brand);
  border-radius: 6em;
  font-family: var(--sans-serif);
  transition: all 0.2s ease;
  font-weight: 400;
  border-bottom: none;

  &:hover,
  &:active {
    background-color: var(--brand-70);
  }

  &.secondary {
    color: var(--text);
    background-color: var(--moonlight);

    &:hover,
    &:active {
      background-color: var(--moonlight-70);
    }
  }

  &.gray {
    color: var(--text);
    background-color: var(--medium-gray);

    &:hover,
    &:active {
      opacity: 0.8;
    }
  }

  &.white {
    color: var(--text);
    background-color: white;

    &:hover,
    &:active {
      opacity: 0.8;
    }
  }
`;

export default function Button({ children, to, className }) {
  // Output secondary class only when present
  let additionalClassName = '';
  if (className) {
    additionalClassName = className;
  }
  return (
    <StyledButton className={`${additionalClassName}`} to={to}>
      <IconArrowRight />
      {children}
    </StyledButton>
  );
}
