import React from 'react';
import 'normalize.css';
// import '../styles/layout.css';
import Header from './Header/Header';
import Footer from './Footer';
import Colors from '../styles/Colors';
import GlobalStyle from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Awards from '../components/Awards';

export default function Layout({ children }) {
  return (
    <>
      <Colors />
      <GlobalStyle />
      <Typography />
      <Header />
      {children}
      <Awards />
      <Footer />
    </>
  );
}
