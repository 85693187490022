import { createGlobalStyle } from 'styled-components';

const Colors = createGlobalStyle`

:root {
  --brand: #3255a4;
  --moonlight: #ddcb77;
  --text: #3c3c3c;
  --moonlight-dark: #ceb464;
  --light-gray: #ebebeb;
  --medium-gray: #d9d9d9;
  --beige: #d6d1c4;
  --cement: #a49f92;

  --solutions-brown: #c68b66;
  --store-green: #768e87;
  /* --store-green: #00F937; */

  // shades
  --brand-05: rgba(50, 85, 164, .05);
  --brand-10: rgba(50, 85, 164, .1);
  --brand-50: rgba(50, 85, 164, .5);
  --brand-60: rgba(50, 85, 164, .6);
  --brand-70: rgba(50, 85, 164, .7);

  --moonlight-70: rgba(221, 203, 119, .7);
  --moonlight-10: rgba(221, 203, 119, .1);
  --moonlight-20: rgba(221, 203, 119, .2);
  --moonlight-05: rgba(221, 203, 119, .05);
}

`;

export default Colors;
