import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { above, below } from '../../styles/MediaQueries';
import logo from '../../assets/mond-logo-2.svg';
import { DesktopSubNav } from './DesktopSubNav.js';
import { DesktopLogoBar } from './DesktopLogoBar.js';
import { IconExternalLink2 } from '../../components/Icons';

// Vars
const mobileSpacing = '1rem';

const StyledNav = styled.nav`
  background-color: var(--brand);
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0;
  border-bottom: 2px solid var(--brand);
  z-index: 1;

  ${above.small`
        position: static;
        border-bottom: none;
    `}

  ul {
    list-style-type: none;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--brand);
    pointer-events: none;
    flex-direction: column;
    transform: translateX(-100%);
    transition: all 0.2s cubic-bezier(0.76, -0.245, 0.24, 1.245);
    ${above.small`
            position: static;
            flex-direction: row;
            transform: translateY(0);
            pointer-events: all;
            opacity: 1;
            padding: 0;
        `}
  }

  li {
    margin: 0;
    padding: 0.75em 2em;
    ${below.medium`
				padding-top: ${mobileSpacing};
				&:last-of-type {
					padding-bottom: ${mobileSpacing};
				}
			`}
  }

  a {
    color: white;
    /* line-height: 0; */
    font-family: var(--sans-serif);
    font-size: var(--font-button);
    padding: 0;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    font-weight: 400;

    &:hover {
      border-bottom: 1px solid white;
      opacity: 1;
    }

    &[aria-current='page'] {
      border-bottom: 1px solid white;
    }
  }

  ul.is-open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
`;

const StyledMobileSubNav = styled.div`
  background-color: var(--light-gray);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: var(--sans-serif);
  font-size: var(--font-button);
  padding: 0;
  ${above.small`
      display: none;
    `}

  li {
    padding-top: ${mobileSpacing};

    &:last-of-type {
      padding-bottom: ${mobileSpacing};
    }

    &.active a {
      color: var(--brand);
      /* font-weight: 600; */
      border-bottom: none;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }

  a {
    color: var(--text);
    &:hover {
      border-bottom: 1px solid;
    }
  }

  svg {
    margin-right: 0;
    position: relative;
    top: 0.125rem;
  }
`;

// Logobar with burger on mobile
const StyledLogoBarMobile = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.25rem;
  a {
    padding: 0;
    line-height: 0;
  }
  img {
    max-width: 25vw;
  }
  .burger {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    transform: rotate(90deg);
    font-weight: 700;
    font-size: 1.125rem;
    transition: all 0.2s cubic-bezier(0.76, -0.245, 0.24, 1.245);
    &.is-open {
      transform: rotate(0);
    }
  }
  ${above.small`
         display: none;
    `}
`;

export default function Header() {
  // get menu titles & links from gatsby-config
  const data = useStaticQuery(graphql`
    query MenuLinkQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  }

  // useEffect(() => {
  //     document.body.classList.toggle('is-open', isOpen);
  //   },[isOpen])
  // close on esc
  useEffect(() => {
    window.addEventListener('keydown', function (event) {
      if (event.key === 'Escape' && isOpen === true) {
        setIsOpen(false);
      }
    });
  });
  let className = 'burger with cheese';
  if (isOpen) {
    className += ' is-open';
  }
  // useEffect(() => {
  //     document.title = `You opened`;
  //     console.log("Yep");
  // }, [isOpen]);
  return (
    <>
      <DesktopSubNav />
      <DesktopLogoBar />
      <StyledNav ref={ref}>
        <StyledLogoBarMobile>
          <Link to="/">
            <img
              className="logo--mobile"
              src={logo}
              alt="MOND Logo"
              onClick={() => (isOpen ? setIsOpen(!isOpen) : '')}
            />
          </Link>
          <button
            className={isOpen ? 'burger is-open' : 'burger'}
            onClick={() => setIsOpen(!isOpen)}
          >
            |||
          </button>
        </StyledLogoBarMobile>
        <ul className={className}>
          {/*Alternative
           https://guusvandewal.nl/drupal-blog/creating-partially-active-links-or-active-trails-react-gatsby
        getProps={({ isPartiallyCurrent }) =>
				isPartiallyCurrent ? { className: 'active-yeah' } : null} */}
          {data.site.siteMetadata.menuLinks.map(menuLink => (
            <li key={menuLink.name}>
              <Link
                to={menuLink.link}
                onClick={() => setIsOpen(!isOpen)}
                activeStyle={{ borderBottom: '1px solid white' }}
                partiallyActive={true}
              >
                {menuLink.name}
              </Link>
            </li>
          ))}

          {/* Mobile Sub Nav */}
          <StyledMobileSubNav>
            <li className="active">
              <Link to="/">MOND Mobilitätsberatung</Link>
            </li>
            <li>
              <a
                href="https://bicicli-solutions.de/"
                target="_blank"
                rel="noreferrer"
              >
                <IconExternalLink2 size="1" />
                BICICLI Cycling Solutions
              </a>
            </li>
            <li>
              <a href="https://bicicli.de/" target="_blank" rel="noreferrer">
                <IconExternalLink2 size="1" />
                BICICLI Concept Store
              </a>
            </li>
          </StyledMobileSubNav>
        </ul>
      </StyledNav>
    </>
  );
}
